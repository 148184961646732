"use client";

// import { LogoSvg } from "@/components/svg/LogoSvg";

// const DefaultLoader = function () {
//   return <LogoSvg className="animate-heart" />;
// };

export default function LoadingPage() {
  return (
    <div className="absolute flex size-full flex-col items-center justify-center">
      {/* <DefaultLoader /> */}
    </div>
  );
}
